import { createStore } from "vuex";
import { cart } from "./cart";
import { global } from "./global";
import { promoCode } from './modules/promoCode';

export default createStore({
    modules: {
        cart: cart,
        global: global,
        promoCode: promoCode
    },
});
