<template>
    <div :class="{ filter: true, 'open-filter': isFilterOpen }">
        <div class="filter__body">
            <div class="filter__top top-filter">
                <div class="top-filter__title">Фільтри</div>
                <button class="top-filter__close _icon-close" @click="closeFilter"></button>
            </div>

            <div class="filter__line">
                <ChecboxFilterSpecial title="В наявності" :isChecked="inStock" @change="onInStockChange" />
                <ChecboxFilterSpecial title="Під замовлення" :isChecked="onOrder" @change="onOnOrderChange" />
            </div>

            <div class="accordion-item" :class="{ filter__line: true, filter__line_active: isActiveIndex(index) }" v-for="(item, index) in filters" :key="index">
                <div class="accordion-item__header" @click="toggleAccordion(index)">{{ item.name }}</div>
                <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave">
                    <div ref="content" class="accordion-item__content" :style="getContentStyle(index)">
                        <Chekbox v-for="(content, i) in item.values" :key="i" :title="content.value" :filterId="item.id" :filterValueId="content.id" @change="onCheckboxChange(item.id, content.id, $event)" />
                    </div>
                </transition>
            </div>

            <div class="filter__line">
                <div class="filter__title">Вартість</div>

                <div class="filter__price-blocks">
                    <div class="filter__price-block">
                        від
                        <input type="text" v-model="minPrice" @input="filterNumericInput" />
                    </div>
                    <div class="filter__price-block">
                        до
                        <input type="text" v-model="maxPrice" @input="filterNumericInput" />
                    </div>
                </div>
                <div class="filter__price-labels">
                    <span class="filter__price-label" @click="setMaxPrice(600)">до 600 ₴</span>
                    <span class="filter__price-label" @click="setMaxPrice(700)">до 700 ₴</span>
                    <span class="filter__price-label max" @click="setMaxPrice(800)">до 800 ₴</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chekbox from "./Chekbox.vue";
import ChecboxFilterSpecial from "./ChecboxFilterSpecial.vue";

export default {
    name: "FilterBtn",
    components: {
        Chekbox,
        ChecboxFilterSpecial,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            activeIndices: [], // Инициализируем пустым массивом
            filters: [],
            typeFilter: [],
            minPrice: 310,
            maxPrice: 3000,
            inStock: false,
            onOrder: false,
        };
    },
    props: {
        isFilterOpen: Boolean,
        closeFilter: Function,
        categoryId: Number,
    },
    methods: {
        toggleAccordion(index) {
            const isActive = this.isActiveIndex(index);

            if (isActive) {
                this.activeIndices = this.activeIndices.filter((i) => i !== index);
            } else {
                this.activeIndices.push(index);
            }

            this.$nextTick(() => {
                this.updateActiveElementsHeight();
            });
        },
        isActiveIndex(index) {
            return this.activeIndices.includes(index);
        },
        getContentStyle(index) {
            const isActive = this.isActiveIndex(index);

            return {
                maxHeight: isActive ? "none" : "0",
            };
        },
        updateActiveElementsHeight() {
            this.activeIndices.forEach((index) => {
                const contentEl = this.$refs.content[index];
                if (contentEl) {
                    contentEl.style.maxHeight = contentEl.scrollHeight + "px";
                }
            });
        },
        setMaxPrice(price) {
            this.maxPrice = price;
            this.$emit("priceChange", { minPrice: this.minPrice, maxPrice: this.maxPrice });
        },
        filterNumericInput(event) {
            const input = event.target;
            input.value = input.value.replace(/\D/g, "");
            this.$emit("priceChange", { minPrice: this.minPrice, maxPrice: this.maxPrice });
        },

        getFilters() {
            this.axios
                .get(this.apiUrl + "filters", {
                    params: {
                        categoryId: this.categoryId,
                    },
                })
                .then((res) => {
                    this.filters = res.data.data;
                    if (this.filters) {
                        this.sortValues();
                    }
                });
        },
        onCheckboxChange(filterId, filterValueId, isChecked) {
            const filter = {
                id: filterId,
                value: filterValueId,
                checked: isChecked,
            };

            this.$emit("filterChange", filter);
        },
        sortValues() {
            this.filters.forEach((item) => {
                item.values.sort((a, b) => {
                    let aValue = 0;
                    if (a && a.value && a.value) {
                        const matchA = a.value.match(/\d+\.?\d*/);
                        if (matchA) {
                            aValue = parseFloat(matchA[0]);
                        }
                    }
                    const matchB = b.value.match(/\d+\.?\d*/);
                    const bValue = matchB ? parseFloat(matchB[0]) : 0;
                    return aValue - bValue;
                });
            });
        },
        onInStockChange(isChecked) {
            this.inStock = isChecked ? 1 : 0;
            this.$emit("inStockChange", this.inStock);
        },
        onOnOrderChange(isChecked) {
            this.inStock = isChecked ? 1 : 0;
            this.$emit("availableForOrderChange", this.inStock);
        },
    },
    mounted() {
        this.getFilters();
    },
    watch: {
        categoryId: {
            immediate: true,
            handler: "getFilters",
        },
        minPrice(newVal) {
            this.$emit("priceChange", { minPrice: newVal, maxPrice: this.maxPrice });
        },
        maxPrice(newVal) {
            this.$emit("priceChange", { minPrice: this.minPrice, maxPrice: newVal });
        },
    },
};
</script>

<style></style>
