<template>
    <header :class="{ header: true, 'menu-open': isMenuOpen }">
        <div class="header__top">
            <div class="header__container header__top-container">
                <!-- <div class="header__text">Знижки до Хелловіну діють до 01.11.23</div> -->
                <div class="header__text">{{ $store.getters.settings.header_text }}</div>
                <div class="header__timer timer">
                    <!-- <div class="timer__text">Знижки до дню відкриття діють ще:</div> -->
                    <div class="timer__text">{{ $store.getters.settings.header_timer_text }}</div>
                    <div class="timer__body">
                        <div class="timer__numbers">{{ formattedTime }}</div>
                        <div class="timer__subs">
                            <span>Дн.</span>
                            <span>Год.</span>
                            <span>Хв.</span>
                            <span>Сек.</span>
                        </div>
                    </div>
                </div>
                <a href="tel:+380937563496" class="header__phone _icon-phone">+38 (093) 756 34 96</a>
            </div>
        </div>

        <div class="header__body">
            <div class="header__container header__container-body">
                <button type="button" class="icon-menu" @click="toggleMenu"><span></span></button>
                <button class="header__search-btn _icon-search" @click="toggleSearch"></button>
                <router-link to="/" class="header__logo"><img src="../assets/img/logo.svg" alt="" /></router-link>

                <div :class="{ header__search: true, 'search-header': true, 'search-open': isSearchOpen }" ref="searchContainer">
                    <button class="search-header__button search-header__button_close _icon-close" @click="toggleSearch"></button>
                    <button class="search-header__button _icon-search" @click="search"></button>
                    <input type="text" class="search-header__input" placeholder="Сережки з перлами та позолотою" v-model="searchQuery" @keyup.enter="search" />
                </div>

                <div class="header__actions actions-header">
                    <!-- <a href="#" class="actions-header__link _icon-user"></a> -->
                    <router-link to="/wishlist" class="actions-header__link _icon-favorite"></router-link>
                    <a href="#" class="actions-header__link _icon-cart" @click.prevent="openCart">
                        <span>{{ $store.getters.cartItemCount }}</span>
                    </a>

                    <!-- <a href="#" class="actions-header__link _icon-cart" @click.prevent="openCartPopup"><span>2</span></a> -->
                </div>
            </div>
        </div>
        <div class="header__bottom">
            <div class="header__container header__container-bottom">
                <div class="header__menu menu">
                    <nav class="menu__body">
                        <button type="button" class="menu__icon icon-menu" @click="toggleMenu"><span></span></button>
                        <ul class="menu__list">
                            <li class="menu__item" v-for="category in categories" :key="category.id">
                                <router-link v-if="!(category.subcategories && category.subcategories.length) || !isMobile" :to="{ name: 'Catalog', params: { categoryId: category.slug } }" @mouseover="toggleSubmenuHover(category.id)" @click="toggleMenu" class="menu__link">{{ category.title }} </router-link>
                                <button v-if="category.subcategories && category.subcategories.length && isMobile" @mouseover="toggleSubmenuHover(category.id)" @touchstart="toggleSubmenu(category.id)" class="menu__link">{{ category.title }}</button>

                                <img v-if="category.subcategories && category.subcategories.length" class="menu-triangle" @mouseover="toggleSubmenuHover(category.id)" @touchstart="toggleSubmenu(category.id)" src="../assets/img/triangle.svg" alt="" />
                                <div class="submenu" :class="{ 'submenu--active': activeSubmenu === category.id }">
                                    <ul v-if="category.subcategories && category.subcategories.length" class="subcategories-wrapper">
                                        <li v-for="subcategory in category.subcategories" :key="subcategory.id">
                                            <router-link v-if="subcategory.id !== 22" :to="{ name: 'SubCatalog', params: { categoryId: category.slug, subCategorySlug: subcategory.slug } }" @click="toggleMenu" class="menu__link">{{ subcategory.title }}</router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ name: 'Catalog', params: { categoryId: category.slug } }" @click="toggleMenu" class="menu__link">Всі вироби</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="menu__item">
                                <router-link :to="{ name: 'Certificate' }" @click="toggleMenu" class="menu__link">Сертифікати</router-link>
                                <!-- <router-link to="/catalog" @click="toggleMenu" class="menu__link">{{ category.title }}</router-link> -->
                            </li>
                            <li class="menu__item menu__item_hide">
                                <a href="tel:+380937563496" class="menu__link _icon-phone">+38 (093) 756 34 96</a>
                                <span>(з 10:00 -18:30)</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <cart-popup :show="isCartOpen" @close="closeCartPopup"></cart-popup>
    <roulette-popup></roulette-popup>
</template>

<script>
import CartPopup from "./CartPopup.vue";
import RoulettePopup from "./RoulettePopup.vue";
import { mapState } from "vuex";

export default {
    name: "AppHeader",
    components: {
        CartPopup,
        RoulettePopup,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            isMenuOpen: false,
            isPopupCartOpen: false,
            isSearchOpen: false,
            remainingTime: localStorage.getItem("remainingTime") || 28800, // Используйте сохраненное значение, если оно есть
            dataModal: false,
            categories: [],
            searchQuery: "",
            activeSubmenu: null,
            // endTime: new Date("2023-11-01T00:00:00+03:00"), // 1.11.23 по Киевскому времени
            endTime: this.calculateNextMidnight(), // Вычисляем время следующей полуночи

            // remainingTime: 0,
            timerInterval: null,
        };
    },

    computed: {
        formattedTime() {
            const days = Math.floor(this.remainingTime / 86400);
            const hours = Math.floor((this.remainingTime % 86400) / 3600);
            const minutes = Math.floor((this.remainingTime % 3600) / 60);
            const seconds = this.remainingTime % 60;

            return `${this.formatDigits(days)}:${this.formatDigits(hours)}:${this.formatDigits(minutes)}:${this.formatDigits(seconds)}`;
        },

        // formattedTime() {
        //     if (this.remainingTime <= 0) {
        //         return "00:00:00:00";
        //     }
        //     const days = Math.floor(this.remainingTime / 86400);
        //     const hours = Math.floor((this.remainingTime % 86400) / 3600);
        //     const minutes = Math.floor((this.remainingTime % 3600) / 60);
        //     const seconds = this.remainingTime % 60;

        //     return `${this.formatDigits(days)}:${this.formatDigits(hours)}:${this.formatDigits(minutes)}:${this.formatDigits(seconds)}`;
        // },

        ...mapState({
            isCartOpen: (state) => state.global.isCartOpen,
        }),
        cartItemCount() {
            const cartItems = localStorage.getItem("cart");
            if (!cartItems) return 0;

            // Преобразование строки JSON в массив объектов
            const cart = JSON.parse(cartItems);

            // Подсчет общего количества товаров
            return cart.reduce((total, item) => total + item.quantity, 0);
        },
        isMobile() {
            return window.innerWidth <= 768; // или другое значение, которое определяет мобильное устройство
        },
        settings() {
            return this.$store.getters.settings;
        },
    },
    mounted() {
        this.menuInit();
        this.addClickOutsideListener();
        // this.startTimer();
        this.getCategories();
        // this.calculateRemainingTime(); // Вызываем метод при монтировании компонента
        // this.timerInterval = setInterval(this.calculateRemainingTime, 1000); // Обновляем каждую секунду

        // this.timerInterval = setInterval(() => {
        //     this.remainingTime--;
        //     localStorage.setItem("remainingTime", this.remainingTime); // Сохраните текущее значение в localStorage

        //     if (this.remainingTime <= 0) {
        //         clearInterval(this.timerInterval);
        //         this.remainingTime = 0; // Установите remainingTime в 0
        //     }
        // }, 1000);

        this.timerInterval = setInterval(this.calculateRemainingTime, 1000);
        this.$store.dispatch("fetchSettings"); // Получаем настройки при монтировании компонента
    },

    startTimer() {
        this.timer = setInterval(() => {
            this.remainingTime--;
            if (this.remainingTime <= 0) {
                clearInterval(this.timer);
                this.remainingTime = 0; // Установите remainingTime в 0
            }
        }, 1000);
    },

    beforeUnmount() {
        clearInterval(this.timerInterval);
        this.removeClickOutsideListener();
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            this.bodyLockToggle();
        },
        bodyLockToggle(delay = 500) {
            if (document.documentElement.classList.contains("lock")) {
                this.bodyUnlock(delay);
            } else {
                this.bodyLock(delay);
            }
        },
        // formatDigits(number) {
        //     return String(number).padStart(2, "0");
        // },

        // calculateRemainingTime() {
        //     const currentTime = new Date();
        //     this.remainingTime = Math.floor((this.endTime - currentTime) / 1000); // В секундах
        // },
        formatDigits(number) {
            return String(number).padStart(2, "0");
        },

        bodyUnlock(delay = 500) {
            let body = document.querySelector("body");
            if (this.bodyLockStatus) {
                let lock_padding = document.querySelectorAll("[data-lp]");
                setTimeout(() => {
                    for (let index = 0; index < lock_padding.length; index++) {
                        const el = lock_padding[index];
                        el.style.paddingRight = "0px";
                    }
                    body.style.paddingRight = "0px";
                    document.documentElement.classList.remove("lock");
                }, delay);
                this.bodyLockStatus = false;
                setTimeout(() => {
                    this.bodyLockStatus = true;
                }, delay);
            }
        },
        bodyLock(delay = 500) {
            let body = document.querySelector("body");
            if (this.bodyLockStatus) {
                let lock_padding = document.querySelectorAll("[data-lp]");
                for (let index = 0; index < lock_padding.length; index++) {
                    const el = lock_padding[index];
                    el.style.paddingRight = window.innerWidth - document.querySelector(".wrapper").offsetWidth + "px";
                }
                body.style.paddingRight = window.innerWidth - document.querySelector(".wrapper").offsetWidth + "px";
                document.documentElement.classList.add("lock");

                this.bodyLockStatus = false;
                setTimeout(() => {
                    this.bodyLockStatus = true;
                }, delay);
            }
        },
        menuInit() {
            if (document.querySelector(".icon-menu")) {
                document.addEventListener("click", (e) => {
                    if (this.bodyLockStatus && e.target.closest(".icon-menu")) {
                        this.bodyLockToggle();
                        document.documentElement.classList.toggle("menu-open");
                    }
                });
            }
        },
        toggleSearch() {
            this.isSearchOpen = !this.isSearchOpen;
        },

        search() {
            if (this.searchQuery.trim() !== "") {
                this.$router.push({ name: "ProductSearch", params: { name: this.searchQuery } });
                this.searchQuery = "";
            }
        },

        addClickOutsideListener() {
            document.addEventListener("click", this.handleClickOutside);
        },
        removeClickOutsideListener() {
            document.removeEventListener("click", this.handleClickOutside);
        },
        handleClickOutside(event) {
            if (this.$refs.searchContainer && !this.$refs.searchContainer.contains(event.target) && !event.target.closest(".header__search-btn")) {
                this.isSearchOpen = false;
            }
        },

        startTimer() {
            this.timer = setInterval(() => {
                this.remainingTime--;
                if (this.remainingTime <= 0) {
                    clearInterval(this.timer);
                }
            }, 1000);
        },

        openCart() {
            this.$store.commit("openCart");
        },

        closeCartPopup() {
            this.isPopupCartOpen = false;
        },
        getCategories() {
            this.axios.get(this.apiUrl + "categories").then((res) => {
                this.categories = res.data.data;
            });
        },
        toggleSubmenuHover(id) {
            if (window.innerWidth > 768) {
                this.activeSubmenu = this.activeSubmenu === id ? null : id;
            }
        },
        toggleSubmenu(id) {
            this.activeSubmenu = this.activeSubmenu === id ? null : id;
        },

        calculateNextMidnight() {
            let now = new Date();
            let nextMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            return nextMidnight;
        },

        calculateRemainingTime() {
            let now = new Date();
            let endTime = this.calculateNextMidnight();
            this.remainingTime = Math.floor((endTime - now) / 1000);

            if (this.remainingTime <= 0) {
                this.endTime = this.calculateNextMidnight(); // Обновите время окончания для следующего дня
                this.remainingTime = Math.floor((this.endTime - now) / 1000);
            }
        },
    },
};
</script>

<style scoped>
.menu__item {
    display: flex;
    position: relative;
}
.menu-triangle {
    transform: rotate(59deg);
    width: 7px;
    transition: transform 0.3s;
}
.submenu ul {
    padding: 10px;
}

@media (min-width: 768px) {
    .submenu ul {
        display: none; /* По умолчанию вложенный список скрыт */
        position: absolute;
        z-index: 9;
        background: #fff;
        left: -10px;
        top: 20px;
    }
    .menu__item:hover .submenu ul {
        display: block; /* При наведении на пункт меню вложенный список становится видимым */
    }
    .menu__item:hover .menu-triangle {
        transform: rotate(-120deg);
    }
    .menu__item_hide {
        display: none;
    }

    .subcategories-wrapper {
        width: 120px;
    }
}

@media (max-width: 768px) {
    .submenu {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.3s, opacity 0.3s;
    }

    .menu__item {
        flex-wrap: wrap;
    }
}

.submenu--active {
    max-height: 350px; /* Выставьте нужную высоту */
    opacity: 1;
}
</style>
