<template>
    <main class="page">
        <section class="delivery">
            <div class="delivery__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br />
                    <p style="text-align: center"><strong style="font-size: 22px">Доставка та оплата</strong></p>
                    <br />
                    <p>Замовлення відправляються службою доставки "Нова Пошта" та «УкрПошта»</p>
                    <br />
                    <h3>Доставка по Україні</h3>
                    <p>● Замовлення при повній оплаті., відправляються безкоштовно по Україні.</p>
                    <p>● Для замовлень до 1000 грн. Покупець оплачує доставку по тарифам перевізника.</p>
                    <p>● Відправлення замовлень НП відбувається кожен день окрім неділі (Пн-Сб).</p>
                    <p>● Відправлення замовлень «УкрПоштою» відбувається два рази на тиждень і потребують повної оплати (Пн-Чт).</p>
                    <p>● Оплата при отриманні у відділенні Нової пошти (накладений платіж) *</p>
                    <p>● Передплата на рахунок ФОП ПриватБанку або МоноБанку (у розмірі 150 гривень).</p>
                    <h3>Міжнародна доставка</h3>
                    <p>● Замовленння сумою від 2500 грн. з доставкою за Кордони України, сплачує покупець за тарифами перевізника.</p>
                    <p>● Міжнародна відправка здійснюється поштовою службою «УкрПошта»</p>
                    <p>● Відправлення замовлень «УкрПоштою» відбувається два рази на тиждень і потребують повної оплати (Пн-Чт).</p>
                    <p>● Строки міжнародної доставки встановлює перевізник, зазвичай це займає 14 днів, але строки можуть відрізнятись.</p>
                    <p>● Відправка товару за кордон відбувається виключно по повній оплаті за товар та повну оплату послуг поштових служб.</p>
                    <br />
                    <p>Зверніть увагу!</p>
                    <br />
                    <p>* При оплаті післяплатою Ви окремо оплачуєте комісію Нової пошти в розмірі 20 грн. + 2% від суми накладеного платежу.</p>
                    <p>* Хоча відправка замовлення відбувається кожен день, ваше замовлення може бути надіслано через 1-3 дні з моменту оформлення.</p>
                    <p>* Якщо вам необхідна термінова відправка будь ласка звʼяжіться з менеджером або залиште коментар до замовлення</p>
                </div>
            </div>
        </section>
    </main>
</template>

<script></script>

<style scoped>
.delivery {
    margin-bottom: 20px;
}
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
}
</style>
