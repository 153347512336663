<template>
    <div class="promocode">
        <div class="promocode__row">
            <input v-model="promoCode" class="input promocode__input" name="promocode" max="7" type="text" placeholder="Ваш промокод" />
            <button class="button promocode__button" @click="checkPromoCode">Застосувати</button>
        </div>
        <div class="promocode__row" v-if="promocodeStatusText">
            <span class="promocode__text" :style="{ color: promocodeStatusColor }" style="margin-bottom: 10px">{{ promocodeStatusText }}</span>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const promoCode = computed({
    get: () => store.state.promoCode.promoCode,
    set: (value) => store.commit("SET_PROMO_CODE", value),
});

const promocodeStatusText = computed(() => store.getters["promocodeStatusText"]);
const promocodeStatusColor = computed(() => store.getters["promocodeStatusColor"]);

function checkPromoCode() {
    store.dispatch("checkPromoCode");
}
</script>

<style lang="scss" scoped>
.promocode {
    display: flex;
    flex-direction: column;
    &__row {
        display: flex;
        align-items: stretch;
        margin-bottom: 10px;
    }

    &__input {
        width: 50%;
        border-radius: 6px !important;

        @media (max-width: $mobile) {
            font-size: 12px !important;
            padding-right: 0 !important;
            padding-left: 0;
            text-align: center;
        }
    }

    &__button {
        width: 50%;
        margin-left: 5px;
        padding: 0 10px;
        height: auto;
    }

    &__text {
        font-size: rem(12);
    }

    &__input {
        font-size: rem(14);
    }
}
</style>
