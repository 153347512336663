<template>
    <div class="counter">
        <button class="counter__button" @click="decreaseCounter">-</button>
        <input type="text" class="counter__input" v-model="counter" @input="handleInput" />
        <button class="counter__button" @click="increaseCounter">+</button>
    </div>
</template>

<script>
export default {
    name: "QtyComponent",
    props: {
        modelValue: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            counter: this.modelValue,
        };
    },
    watch: {
        counter(newValue) {
            this.$emit("update:modelValue", Number(newValue));
        },
    },
    methods: {
        increaseCounter() {
            this.counter++;
        },
        decreaseCounter() {
            if (this.counter > 1) {
                this.counter--;
            }
        },
        handleInput() {
            this.counter = this.counter.replace(/\D/g, "") || 1;
        },
    },
};
</script>

<style scoped></style>
