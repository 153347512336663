import axios from "axios";

export const global = {
    state: () => ({
        isCartOpen: false,
        apiUrl: process.env.VUE_APP_PROD_API_URL,
        settings: [], // Добавляем массив настроек
    }),
    getters: {
        cartItemCount: () => {
            const cartItems = localStorage.getItem("cart");
            if (!cartItems) return 0;

            const cart = JSON.parse(cartItems);
            return cart.reduce((total, item) => total + item.quantity, 0);
        },
        settings: (state) => state.settings,
    },
    mutations: {
        toggleCart(state) {
            state.isCartOpen = !state.isCartOpen;
        },
        openCart(state) {
            state.isCartOpen = true;
        },
        closeCart(state) {
            state.isCartOpen = false;
        },
        setSettings(state, settings) {
            state.settings = settings;
        },
    },
    actions: {
        getImageProduct(media) {
            return media.path ? this.$uploadUrl + media.path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
        async fetchSettings({ commit, state }) {
            try {
                const response = await axios.get(`${state.apiUrl}settings`);
                commit('setSettings', response.data);
            } catch (error) {
                console.error('Ошибка при получении настроек:', error);
            }
        },
    },
};
