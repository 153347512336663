// В файле cart.js
import axios from "axios";

export const cart = {
    state: () => ({
        cartCount: 1,
        cartItems: JSON.parse(localStorage.getItem("cart")) || [],
        apiUrl: process.env.VUE_APP_PROD_API_URL,
        productPackage: [],
        productPackageIds: [],
        totalPriceWithoutDiscount: 0,
        enableSecondItemDiscount: false, // Флаг для скидки на каждый второй товар
        enableAdditionalDiscount: true,  // Флаг для дополнительной скидки при 2 и более товарах
    }),
    getters: {
        changeCartCount(state) {
            return state.cartCount + 11;
        },
        totalPriceCart(state, getters, rootState) {
            const promoCodeState = rootState.promoCode;

            // Calculate the total price without promo code discount
            const total = (state.cartItems || []).reduce((total, item) => {
                let price = item.variant?.price ?? item.product.price;
                let itemTotal = price * item.quantity;
                let discount = item.discount_amount || 0;
                return total + itemTotal - discount;
            }, 0);

            let totalWithDiscount = total;

            // Apply promo code discount
            if (promoCodeState.discountType && promoCodeState.discountData) {
                if (promoCodeState.discountType === 'PERCENT') {
                    totalWithDiscount = total * (1 - promoCodeState.discountData / 100);
                } else if (promoCodeState.discountType === 'AMOUNT') {
                    totalWithDiscount = total - promoCodeState.discountData;
                }
            }

            // Ensure the total doesn't go below zero
            totalWithDiscount = Math.max(0, totalWithDiscount);
            console.log('totalWithDiscount', Number(total.toFixed(2)));

            // Сохраняем значение total в состояние
            state.totalPriceWithoutDiscount = total;

            return Number(totalWithDiscount.toFixed(2));
        },

        totalPriceWithoutDiscount(state) {
            return state.totalPriceWithoutDiscount;
        },

        getProductPackage(state) {
            return state.productPackage;
        },
        getProductPackageIds(state) {
            return state.productPackageIds;
        },
        totalOldPriceCart(state) {
            const totalOldPrice = (state.cartItems || []).reduce((total, item) => {
                let oldPrice = item.variant?.old_price ?? item.product?.old_price ?? 0;
                let itemTotal = oldPrice * item.quantity;
                return total + itemTotal;
            }, 0);
            // console.log('totalOldPrice', Number(totalOldPrice.toFixed(2)));
            return Number(totalOldPrice.toFixed(2));
        },
        totalDiscountAmount(state) {
            const totalDiscount = (state.cartItems || []).reduce((total, item) => {
                let oldPrice = item.variant?.old_price ?? item.product?.old_price;
                let price = item.variant?.price ?? item.product.price;

                let defaultDiscount = oldPrice ? (oldPrice - price) * item.quantity : 0;
                let additionalDiscount = item.discount_amount || 0;

                return total + defaultDiscount + additionalDiscount;
            }, 0);
            return Number(totalDiscount.toFixed(2));
        },

    },
    mutations: {
        updateCartItems(state, newCartItems) {
            state.cartItems = newCartItems;
            localStorage.setItem("cart", JSON.stringify(newCartItems));
        },
        setProductPackage(state, productPackage) {
            state.productPackage = productPackage;
        },
        addProductPackageIds(state, productPackageIds) {
            state.productPackageIds = [...new Set([...state.productPackageIds, ...productPackageIds])];
        },
        // Добавляем мутацию для удаления товара из корзины
        removeCartItem(state, itemId) {
            state.cartItems = state.cartItems.filter(item => item.id !== itemId);
            localStorage.setItem("cart", JSON.stringify(state.cartItems));
        },
        setDiscountFlags(state, { secondItem, additional }) {
            state.enableSecondItemDiscount = secondItem;
            state.enableAdditionalDiscount = additional;
        },
    },
    actions: {
        applyDiscounts({ state, commit }) {
            // Клонируем cartItems, чтобы не мутировать состояние напрямую
            let cartItems = JSON.parse(JSON.stringify(state.cartItems)) || [];

            // Сбрасываем все скидки
            cartItems.forEach((item) => {
                item.discount_amount = 0;
            });

            // Исключаем товары из productPackageIds
            const eligibleItems = cartItems.filter(item => !state.productPackageIds.includes(item.product.id));

            // Рассчитываем общее количество товаров без учета исключенных
            const totalQuantity = eligibleItems.reduce((sum, item) => sum + item.quantity, 0);

            if (state.enableSecondItemDiscount) {
                // Определяем количество товаров, на которые должна быть применена скидка
                const discountQuantity = Math.floor(totalQuantity / 2);

                if (discountQuantity > 0) {
                    // Сортируем товары по цене за единицу в порядке возрастания
                    let sortedItems = eligibleItems.slice().sort((a, b) => {
                        let priceA = a.variant?.price ?? a.product.price;
                        let priceB = b.variant?.price ?? b.product.price;
                        return priceA - priceB;
                    });

                    // Применяем скидку к самым дешевым товарам
                    let remainingDiscountQuantity = discountQuantity;

                    for (let item of sortedItems) {
                        let unitPrice = item.variant?.price ?? item.product.price;
                        let quantityToDiscount = Math.min(item.quantity, remainingDiscountQuantity);

                        let discountPerUnit = unitPrice * 0.444444;

                        item.discount_amount = discountPerUnit * quantityToDiscount;

                        remainingDiscountQuantity -= quantityToDiscount;

                        if (remainingDiscountQuantity <= 0) {
                            break;
                        }
                    }
                }
            }

            if (state.enableAdditionalDiscount && totalQuantity >= 2) {
                // Логика дополнительной скидки на все товары, кроме eligibleItems
                const additionalDiscountRate = 0.055555; // Пример: 5% скидка
                cartItems.forEach(item => {
                    if (!state.productPackageIds.includes(item.product.id)) { // Исключаем eligibleItems
                        let price = item.variant?.price ?? item.product.price;
                        item.discount_amount += price * additionalDiscountRate * item.quantity;
                    }
                });
            }

            // Обновляем cartItems в состоянии
            commit("updateCartItems", cartItems);
        },

        fetchCartFromLocalStorage({ commit, dispatch }) {
            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
                commit("updateCartItems", cart);
                dispatch("applyDiscounts");
            }
        },
        updateCart({ commit, dispatch }, newCartItems) {
            // Обновление localStorage
            localStorage.setItem("cart", JSON.stringify(newCartItems));
            // Обновление Vuex store
            commit("updateCartItems", newCartItems);
            dispatch("applyDiscounts");
        },
        // Добавляем действие для удаления товара из корзины
        removeCartItem({ commit, dispatch }, itemId) {
            commit("removeCartItem", itemId);
            dispatch("applyDiscounts");
        },
        async fetchProductPackage({ commit, state }) {
            try {
                const response = await axios.get(state.apiUrl + `products/tags/package`);
                if (response.data) {
                    commit("setProductPackage", response.data.data);
                    commit(
                        "addProductPackageIds",
                        response.data.data.map((product) => product.id)
                    );
                }
            } catch (error) {
                console.error("Ошибка при получении данных о продукте:", error);
            }
        },

        async fetchProductsTagsNoPromo({ commit, state }) {
            try {
                const response = await axios.get(state.apiUrl + `products/tags/no_promotion?filter=ids`);
                if (response.data) {
                    commit(
                        "addProductPackageIds",
                        response.data.data.map((product) => product.id)
                    );
                }
            } catch (error) {
                console.error("Ошибка при получении данных о продукте:", error);
            }
        },
    },
};