<template>
    <div class="top-page">
        <div class="top-page__container">
            <div class="top-page__breadcrumbs breadcrumbs">
                <ul class="breadcrumbs__list">
                    <li class="breadcrumbs__item"><a href="" class="breadcrumbs__link">Le’Perle</a></li>
                    <li class="breadcrumbs__item"><span class="breadcrumbs__current">Сертифікати</span></li>
                </ul>
            </div>
            <div class="top-page__body">
                <h1 class="top-page__title" data-title="Certificates">Сертифікати</h1>
            </div>
        </div>
    </div>

    <section class="certificates">
        <div class="certificates__container">
            <div class="certificates__body">
                <div class="certificates__content">
                    <div class="certificates__text">
                        <p>Зробіть вибір простим для ваших близьких - оберіть сертифікат Le'Perle, який дозволяє їм насолоджуватися безкінечними можливостями вибору з нашого розкішного асортименту.</p>
                        <p>Це не просто подарунок, це можливість збудувати свою власну колекцію чарівних прикрас, які завжди нагадуватимуть про вашу любов. Наші сертифікати доступні на будь-яку суму.</p>
                    </div>
                    <Certificates title="Обери суму:" :sizes="certificate" class="certificates__sizes-block" @selected="handleselectedVariant" />
                    <button class="certificates__button button" @click="addToCart">Замовити сертифікат</button>
                </div>

                <div class="certificates__image-ibg">
                    <img src="../assets/img/certificate/IMG_9170.webp" alt="IMG_9170.webp" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Certificates from "./Certificates.vue";

import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";

export default {
    components: {
        Certificates,
    },
    data() {
        return {
            cart: [], // массив для хранения данных о сертификатах в корзине
        };
    },

    setup() {
        const store = useStore();
        const apiUrl = process.env.VUE_APP_PROD_API_URL;
        const selectedVariant = ref(null);
        const certificate = ref([]);
        const product = ref();

        const getCertificate = async () => {
            try {
                const response = await axios.get(apiUrl + "products/tags/certificate");
                certificate.value = response.data.data[0].variants;
                product.value = response.data.data[0];
            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    this.$router.push({ name: "NotFoundPage" });
                }
            }
        };

        onMounted(() => {
            getCertificate();
        });

        const addToCart = () => {
            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
            } else {
                cart = [];
            }

            let itemToAdd = selectedVariant.value;
            itemToAdd.product = product.value;
            itemToAdd.version_update = 1;

            const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
            if (existingProductIndex >= 0) {
                cart[existingProductIndex].quantity++;
            } else {
                cart.push(itemToAdd);
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            document.dispatchEvent(new CustomEvent("cart-updated"));
            store.commit("openCart");

            store.dispatch("updateCart", cart);
        };

        const handleselectedVariant = (item) => {
            selectedVariant.value = item;
        };

        return {
            addToCart,
            selectedVariant,
            handleselectedVariant,
            certificate,
        };
    },

    methods: {},
};
</script>

<style></style>
