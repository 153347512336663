import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import axios from "axios";
// import { Cookies } from "vue3-cookies";

import { defaulLocale } from "./i18n";
import { languages } from "./i18n";
import { createI18n, useI18n } from "vue-i18n";
import store from "./store";
import VueLazyload from "vue-lazyload";
import { createHead } from "@vueuse/head";
import cookiePlugin from "./plugin/cookiePlugin";
import eventidGenerate from "./plugin/eventidGenerate";
import toFixed from "./plugin/toFixed";
import globalProperties from './plugin/globalProperties';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import spinerImage from "./assets/img/giphy.gif";
import './registerServiceWorker'

import * as Sentry from "@sentry/vue";

const messages = Object.assign(languages);
const i18n = createI18n({
    legacy: false,
    locale: defaulLocale,
    fallbackLocale: "ru",
    messages,
});

const app = createApp(App, {
    setup() {
        const { t } = useI18n();
        return { t };
    },
});

const head = createHead();

app.use(VueLazyload, {
    preLoad: 1.3,
    error: "path/to/error/image",
    loading: spinerImage,
    attempt: 1,
});

const onlyCountries = [
    'UA', // Україна
    'AT', // Австрія
    'BE', // Бельгія
    'GB', // Велика Британія
    'DE', // Німеччина
    'DK', // Данія
    'ES', // Іспанія
    'IT', // Італія
    'IL', // Ізраїль
    'NL', // Нідерланди
    'NO', // Норвегія
    'PL', // Польща
    'PT', // Португалія
    'FR', // Франція
    'CH', // Швейцарія
    'SE', // Швеція
    'EE', // Естонія
    'LV', // Латвія
    'LT', // Литва
    'FI', // Фінляндія
    'CZ', // Чеська Республіка
    'SK', // Словаччина
    'HU', // Угорщина
    'SI', // Словенія
    'HR', // Хорватія
    'BA', // Боснія і Герцеговина
    'RS', // Сербія
    'ME', // Чорногорія
    'MK', // Македонія
    'AL', // Албанія
    'GR', // Греція
    'BG', // Болгарія
    'RO', // Румунія
    'MD', // Молдова
    'AD', // Андорра
    'MC', // Монако
    'LI', // Ліхтенштейн
    'SM', // Сан-Марино
    'VA', // Ватикан
    'IS', // Ісландія
    'MT', // Мальта
    'CY', // Кіпр
    'TR', // Туреччина
    'CA', // Канада
    'US', // Сполучені Штати Америки
    'MX', // Мексика
    'GT', // Гватемала
    'BZ', // Беліз
    'HN', // Гондурас
    'SV', // Сальвадор
    'NI', // Нікарагуа
    'CR', // Коста-Ріка
    'PA', // Панама
    'CO', // Колумбія
    'VE', // Венесуела
    'GY', // Гаяна
    'SR', // Суринам
    'EC', // Еквадор
    'PE', // Перу
    'BO', // Болівія
    'BR', // Бразилія
    'PY', // Парагвай
    'CL', // Чилі
    'AR', // Аргентина
    'AU', // Австралія
    'AE'  // Об'єднані Арабські Емірати
];


const globalOptions = {
    mode: "international",
    onlyCountries: onlyCountries,
    ignoredCountries: ['RU']
};

Sentry.init({
    app,
    dsn: "https://436525f1863733e08ee9ab49f208d045@o4508276412841984.ingest.de.sentry.io/4508276414742608",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://le-perle.com.ua"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(VueLazyload);
app.use(i18n);
app.use(store);
app.use(router);
app.use(head);
app.use(cookiePlugin);
app.use(eventidGenerate);
app.use(toFixed);
app.use(globalProperties);
app.use(VueTelInput, globalOptions);

store.dispatch("global/checkAndSetIdentifier");

app.config.globalProperties.axios = axios;


app.mount("#app");
