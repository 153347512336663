<template>
    <div class="thanks">
        <div class="thanks__container">
            <div class="thanks__wrapper">
                <div class="thanks__image">
                    <img src="../assets/img/thanks.jpg" alt="thanks.jpg" />
                </div>
                <div class="thanks__content">
                    <h1>Дякуємо за замовлення!</h1>
                    <p>Ваше замовлення №{{ orderId }} оформлене! Лист підтвердження відправлено на ваш e-mail</p>
                </div>
                <div class="thanks__buttons" bis_skin_checked="1">
                    <a href="/" class="thanks__button button button_line"> Продовжити покупки </a>
                    <a href="/" class="thanks__button button"> Перейти на головну </a>
                </div>
            </div>
        </div>
    </div>
    <ProductSlider :title="'Інші клієнти також купили'" :products="viewedProducts" id="1" />
    <ProductSlider :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
</template>
<script>
// /* global dataLayer */

// import { onMounted, ref, computed, getCurrentInstance } from "vue";
import { onMounted, ref } from "vue";
import ProductSlider from "./ProductSlider.vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";
// import axios from "axios";

export default {
    components: {
        ProductSlider,
    },
    data() {
        return {
            viewedProducts: [],
        };
    },
    setup() {
        const route = useRoute();
        const orderId = ref(null);
        // const store = useStore();
        // const totalPrice = computed(() => store.getters.totalPriceCart);
        // const component = getCurrentInstance(); // Получение доступа к экземпляру компонента
        // const apiUrl = process.env.VUE_APP_PROD_API_URL;
        // const getCookie = component.appContext.config.globalProperties.$getCookie;
        // const getEventId = component.appContext.config.globalProperties.$getEventId;
        // const event_id = getEventId();
        // const external_id = localStorage.getItem("external_id");
        onMounted(async () => {
            //     // Получаем ID заказа
            orderId.value = route.params.orderId;
        });
        // // localStorage.setItem("cart", JSON.stringify([]));
        // // document.dispatchEvent(new CustomEvent("cart-updated"));
        return {
            orderId,
        };
    },
    mounted() {
        this.getViewProducts();
    },
    methods: {
        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },
    },
    watch: {},
};
</script>

<style></style>
