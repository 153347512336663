<!-- src/components/CartItems.vue -->
<template>
    <div class="cart__items-wrapper">
        <div class="cart__items" v-for="item in cartItems" :key="item.id">
            <div class="cart__item item-cart">
                <div v-if="item?.gift && getAmoutFroFreeGift() !== 0" class="cart-item-disabled"></div>
                <div v-if="item?.gift && getAmoutFroFreeGift() !== 0" class="cart__progress-block cart-progress-block">
                    <div class="cart__progress"><span :style="{ width: getProgressWidthAmoutForFreeGift() }"></span></div>
                    <span>До отримання подарунку залишилось:</span>
                    <span>{{ getAmoutFroFreeGift() }} грн.</span>
                </div>
                <router-link :to="{ name: 'Product', params: { slug: item.slug ?? item.product.slug } }" class="item-cart__image-ibg">
                    <img :src="getImageProduct(item)" alt="" />
                </router-link>
                <div class="item-cart__content">
                    <div class="item-cart__info">
                        <div class="item-cart__title">
                            <router-link :to="{ name: 'Product', params: { slug: item.slug ?? item.product.slug } }">
                                {{ item.name ?? item.product.name?.ua ?? item.product.name }}
                            </router-link>
                        </div>
                        <div class="item-cart__material" v-for="optionValues in filterOptionValues(item.variant.option_values)" :key="optionValues.id">
                            {{ getOptionLabel(optionValues) }}<span v-if="item.product.category">{{ transformWordToSingular(item.product.category.title?.ua) }}</span
                            >: {{ optionValues.value }}
                        </div>

                        <div class="item-cart__material" v-if="item.engraving">Гравіювання: {{ item.engraving }}</div>

                        <Counter v-if="!item?.gift" v-model="item.quantity" class="item-cart__counter counter_small" :productIdLimited="getProductsLimitAdditionalsCart()" :productId="item.id" :countProducts="countProductsCart()" @update:modelValue="(newQuantity) => updateQuantity(item, newQuantity)" />
                    </div>
                    <div class="item-cart__price">
                        <div class="item-cart__number">
                            <span v-if="(item.variant.old_price && item.variant.old_price !== null && item.variant.old_price !== '0.00') || (item.product.old_price && item.product.old_price !== null && item.product.old_price !== '0.00')" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ calculateOldPrice(item) }} грн. </span>
                            <span>{{ toFixed(item.price * item.quantity - item.discount_amount) }} грн.</span>
                        </div>
                        <button class="item-cart__button _icon-delete" @click="removeItem(item.id)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Counter from "./Counter.vue";

export default {
    name: "CartItems",
    components: {
        Counter,
    },
    setup() {
        const store = useStore();
        const { appContext } = getCurrentInstance();
        const toFixed = appContext.config.globalProperties.$toFixed;
        const productPackageIds = computed(() => store.getters.getProductPackageIds);
        const cartItems = computed(() => store.state.cart.cartItems);
        const uploadUrl = appContext.config.globalProperties.$uploadUrl;
        // const discountPrecentDefaut = store.state.app.discountPrecentDefaut;
        // const discountPrecentAdditional = store.state.app.discountPrecentAdditional;

        const getImageProduct = (item) => {
            if (item.media) return item.media;
            return item.product.downloads[0] ? uploadUrl + item.product.downloads[0].path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        };

        const getOptionLabel = (optionValues) => {
            if (optionValues.option.name === "size") {
                return "Розмір";
            } else if (optionValues.option.name === "clasp") {
                return "Застібка";
            } else if (optionValues.option.name === "length") {
                return "Довжина";
            } else if (optionValues.option.name === "color") {
                return "Колір";
            }
            // Вернуть оригинальное значение, если нет соответствующего условия
            return optionValues.option.name;
        };

        const transformWordToSingular = (word) => {
            const dictionary = {
                Браслети: "браслету",
                Кольє: "кольє",
            };
            return dictionary[word] || ""; // Вернёт изменённое слово или исходное, если его нет в словаре
        };

        const filterOptionValues = (optionValues) => {
            if (optionValues && Array.isArray(optionValues)) {
                return optionValues.filter((option) => option && option.value !== null && option.value !== undefined);
            }
            return []; // Возвращаем пустой массив, если optionValues undefined или пуст
        };

        const removeItem = (itemId) => {
            store.dispatch("removeCartItem", itemId);
        };

        const updateQuantity = (item, newQuantity) => {
            let updatedCartItems = [...cartItems.value];

            // Находим индекс товара по его ID
            const itemIndex = updatedCartItems.findIndex((i) => i.id === item.id);
            if (itemIndex !== -1) {
                // Обновляем количество товара
                updatedCartItems[itemIndex].quantity = newQuantity;
                // Вызываем действие `updateCart` с обновленным массивом
                store.dispatch("updateCart", updatedCartItems);
            }
        };

        const countProductsCart = () => {
            return cartItems.value.reduce((total, item) => {
                if (!productPackageIds.value.includes(item.product.id)) {
                    return total + item.quantity;
                }
                return total;
            }, 0);
        };

        const getProductsLimitAdditionalsCart = () => {
            return cartItems.value.filter((item) => item.product.tags && item.product.tags.some((tag) => tag.value === "limit_additions_to_cart")).map((item) => item.product.id);
        };

        const calculateOldPrice = (item) => {
            // if (item.is_set === 1) {
            //     let price = 0;
            //     Object.values(item.sets).forEach((itemSet) => {
            //         price += parseFloat(itemSet.old_price);
            //     });
            //     return toFixed(price);
            // }
            return toFixed((item.variant.old_price ?? item.product.old_price) * item.quantity);
        };

        const getAmoutFroFreeGift = () => {
            const total = store.getters["totalPriceCart"];
            return total >= 1000 ? 0 : Math.round(1000 - total);
        };

        const getProgressWidthAmoutForFreeGift = () => {
            const total = store.getters["totalPriceCart"];
            return total >= 1000 ? "100%" : `${(total / 1000) * 100}%`;
        };

        return {
            toFixed,
            getImageProduct,
            getOptionLabel,
            transformWordToSingular,
            filterOptionValues,
            removeItem,
            updateQuantity,
            countProductsCart,
            getProductsLimitAdditionalsCart,
            calculateOldPrice,
            getAmoutFroFreeGift,
            getProgressWidthAmoutForFreeGift,
            cartItems,
        };
    },
};
</script>

<style scoped>
.cart-item-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.cart__progresses {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

.cart__progress-block {
    margin-bottom: 0;
}

.cart-progress-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2;
}

.item-cart__discount {
    color: #ff0000;
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
}
</style>
